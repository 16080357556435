<template>
  <div>
    <el-form-item
      :label="currentField.label"
      :required="this.currentField.isRequired"
    >
    </el-form-item>
    <div :span="currentField.span">
      <bar-chart :chart-data="datacollection"></bar-chart>
      <!-- <button @click="fillData()">Randomize</button> -->
    </div>
  </div>
</template>

<script>
import BarChart from './BarChart.js'
export default {
  name: 'GraphBar',
  props: ['currentField'],
  components: { BarChart },
  data() {
    return {
      input: '',
      datacollection: null
    }
  },
  mounted() {
    this.fillData()
  },
  computed: {
    // customStyles() {
    //   return {
    //     height: `${this.currentField.height}px`
    //   }
    // },
    attributesBinding() {
      var attr = {}
      if (this.currentField.name) {
        attr.name = this.currentField.name
      }

      if (this.currentField.isPlaceholderVisible) {
        attr.placeholder = this.currentField.placeholder
      }

      return attr
    }
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: [this.getRandomInt(), this.getRandomInt()],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          },
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }
        ]
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    }
  }
}
</script>
<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>
