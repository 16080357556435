<template>
  <div>
    <el-container>
      <el-main class="el-main-left">
        <div class="wrapper--forms">
          <el-form>
            <!-- <el-row>
              <el-button
                style="margin-bottom: 10px;"
                type="success"
                @click="submitForm"
                :loading="loading"
                >บันทึก</el-button
              >
            </el-row> -->
            <template>
              <div class="section-block">
                <div class="source">
                  <el-row>
                    <el-col :span="18">
                      {{ form.title }}
                      <!-- <el-input
                        readonly=""
                        placeholder="Please input section title"
                        :data-index="eachFormIndex"
                        v-model="eachFormObj.title"
                        style="width: 100%;"
                      ></el-input> -->
                    </el-col>
                    <el-col :span="6">
                      <el-button
                        style="margin-bottom: 10px;float: right;"
                        type="success"
                        @click="submitReport"
                        :loading="loading"
                        >บันทึก</el-button
                      >
                    </el-col>
                  </el-row>
                </div>
                <div class="meta">
                  <el-row>
                    <draggable
                      :list="report.fields"
                      class="dragArea"
                      :group="{ name: 'reportbuilder', pull: false, put: true }"
                      :sort="true"
                      ghost-class="sortable__ghost"
                    >
                      <!-- The form elements starts (on the right) -->
                      <!-- <div> -->
                      <el-col
                        v-for="(field, index) in report.fields"
                        :key="index"
                        v-bind="field"
                        :span="field.span"
                        class="form__group"
                        :class="{ 'is--active': field === getActiveField }"
                      >
                        <span class="form__selectedlabel">{{
                          field.fieldType
                        }}</span>

                        <div @click="editElementProperties(field)">
                          <!-- <label class="form__label" v-model="form.label" v-show="form.hasOwnProperty('label')">{{ form.label }}</label> -->
                          <component
                            :is="field.fieldType"
                            :currentField="field"
                            class="form__field"
                          >
                          </component>
                        </div>

                        <!-- Actions list -->
                        <div class="form__actiongroup">
                          <el-button
                            circle
                            size="mini"
                            icon="el-icon-rank"
                            class="form__actionitem--move"
                          ></el-button>

                          <el-button-group class="form__actionlist">
                            <el-button
                              size="mini"
                              icon="el-icon-plus"
                              @click="cloneElement(index, field, report.fields)"
                              v-show="!field.isUnique"
                            ></el-button>
                            <el-button
                              size="mini"
                              icon="el-icon-delete"
                              @click="deleteElement(index, report.fields)"
                            ></el-button>
                          </el-button-group>
                        </div>
                      </el-col>
                      <!-- </div> -->
                    </draggable>
                  </el-row>
                </div>
              </div>
            </template>
          </el-form>
        </div>
      </el-main>
      <el-aside class="wrapper--sidebar" width="25%">
        <el-tabs type="border-card" v-model="activeTabForFields">
          <el-tab-pane name="elements" label="Elements">
            <elements />
          </el-tab-pane>

          <el-tab-pane name="properties" label="Properties">
            <!-- Object.keys($store.activeField).length -->
            <properties></properties>
          </el-tab-pane>
        </el-tabs>
        <!--{{ $store.activeField }}-->
      </el-aside>
    </el-container>
  </div>
</template>

<script>
import { getFormById, saveReport } from '@/api/form'
import { ReportBuilder } from '@/components/report_elements/reportbuilder'
import { mapState, mapGetters } from 'vuex'
import lodash from 'lodash'
const {
  Elements,
  Properties,
  draggable,
  // TextInput,
  TextComponent,
  LongTextInput,
  // NumberInput,
  // SelectList,
  // RadioButton,
  // Checkbox,
  // TimePicker,
  // DatePicker,
  // DatetimePicker,
  // Rating,
  // Button,
  // Carousel,
  // Upload,
  // ElSwitch,
  TableComponent,
  HtmlComponent,
  GraphBar
  // ImageButton,
  // RadioTextButton
} = ReportBuilder.$options.components
export default {
  name: 'DesignReport',
  props: ['parentId'],
  data() {
    return {
      loading: false,
      sortElementOptions: ReportBuilder.$data.sortElementOptions,
      form: { title: '' }
    }
  },
  created() {
    getFormById(this.$route.params.id)
      .then((result) => {
        console.log(result)
        this.form = result
        if (result.report) {
          this.report = result.report
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  computed: {
    ...mapState({
      // forms: (state) => state.reportElements.forms
      // getActiveTabForFields: (state) => state.reportElements.activeTabForFields
    }),
    ...mapGetters({
      getReport: 'getReport',
      reportCount: 'getReportCount',
      getActiveField: 'getReportActiveField'
    }),
    activeTabForFields: {
      get() {
        return this.$store.state.reportElements.activeTabForFields
      },
      set(value) {
        this.$store.commit('SET_REPORT_ACTIVE_TAB', value)
      }
    },
    report: {
      get() {
        return this.$store.getters.getReport
      },
      set(value) {
        this.$store.commit('SET_REPORT', value)
      }
    },
    pages: {
      get() {
        return this.$store.getters.reportPages(this.parentId)
      },
      set(value) {
        this.$store.commit('setReportPages', {
          value,
          parentId: this.parentId
        })
      }
    }
  },
  mounted() {
    console.log(this.$route.params.id)
    console.log('report ->', this.report)
    console.log('activeField ->', this.getActiveField)
  },
  components: {
    Elements,
    Properties,
    draggable,
    // TextInput,
    LongTextInput,
    TextComponent,
    // NumberInput,
    // SelectList,
    // RadioButton,
    // Checkbox,
    // TimePicker,
    // DatePicker,
    // DatetimePicker,
    // Rating,
    // Button,
    // Carousel,
    // Upload,
    // ElSwitch,
    TableComponent,
    HtmlComponent,
    GraphBar
    // ImageButton,
    // RadioTextButton
  },
  methods: {
    isParent(page) {
      return page.children.length > 0
    },
    changeReportTitle() {},
    deleteElement(index, report) {
      this.$store.commit('SET_REPORT_ACTIVE_FIELD', [])
      this.$store.commit('SET_REPORT_ACTIVE_TAB', 'elements')
      this.$delete(report, index)
    },
    cloneElement(index, field, report) {
      var cloned = lodash.cloneDeep(field) // clone deep lodash
      report.splice(index, 0, cloned)
    },
    editElementProperties(field) {
      console.log(field)
      this.$store.commit('SET_REPORT_ACTIVE_TAB', 'properties')
      this.$store.commit('SET_REPORT_ACTIVE_FIELD', field)
    },
    updateReportTitle(title) {
      this.$store.commit('updateReportTitle', { index: 0, title })
    },
    addSection() {
      const reportObj = {
        title: '',
        fields: []
      }
      this.$store.commit('ADD_REPORT', reportObj)
    },
    // Save report
    submitReport() {
      this.loading = true
      saveReport(this.$route.params.id, {
        description: '',
        data: this.reports
      })
        .then(() => {
          this.$notify({
            group: 'notify',
            title: 'บันทึกข้อมูลเรียบร้อย'
          })
        })
        .catch((err) => {
          this.$notify({
            group: 'notify',
            title: 'เกิดข้อผิดพลาด',
            text: err.message
          })
        })
        .finally(() => {
          this.loading = false
        })

      // this.$store.commit('SAVE_SESSION')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.empty-section {
  text-align: center;
  font-size: 40px;
  background: linear-gradient(to bottom, #fff, #409eff);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.dragArea {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  min-height: 10px;
  height: calc(30vh);
  z-index: 2;
}

.el-main-left {
  height: calc(100vh);
}

.form__selectedlabel {
  display: none;
  background: #ecf5ff;
  padding: 3px 5px;
  color: black;
  font-size: 10px;
  position: absolute;
  top: -17px;
  right: 15px;
}

.form__actionitem--move {
  position: absolute;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;

  &:active,
  &:focus,
  &:hover {
    border-color: #409eff;
    background: #ecf5ff;
  }
}

.form__actionlist {
  position: absolute;
  margin-top: 10px;
  visibility: hidden;
  z-index: 3;
  right: 0;
  border-radius: 2px;
}

.form__group {
  margin-bottom: 25px;
  border: 1px solid transparent;
  position: relative;

  &:hover {
    border-color: #409eff;

    .form__actionitem--move {
      visibility: visible;
    }
  }

  &.is--active {
    border-color: #409eff;
    background: #ecf5ff;

    .form__actionlist {
      visibility: visible;
    }
    .form__selectedlabel {
      display: inline-block;
    }
  }
}

.section-block {
  border: 1px solid #ebebeb;
  border-radius: 3px;
}

.section-block .source {
  padding: 10px;
}

.section-block .meta {
  background-color: #fafafa;
  border-top: 1px solid #eaeefb;
}

.wrapper--sidebar {
  padding: 5px;

  .el-tabs {
    min-height: 450px;
    border-bottom: 0;
  }

  .el-tabs__header {
    margin-bottom: 0;
  }

  .el-tabs__content {
    padding: 0;
  }

  .el-tabs__inner {
    padding: 5px;
  }

  .el-tabs__nav {
    float: none;

    .el-tabs__item {
      width: 40%;
      text-align: center;
    }
  }

  .el-tabs--border-card {
    border: 1px solid gray;
  }

  .el-form-item__label {
    line-height: 1;
    text-align: left;
  }
}

.el-input-number {
  width: auto !important;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: auto !important;
}

// Preview
.preview__wrapper {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.el-form-item {
  padding: 10px;
  margin-bottom: 0;
}
</style>
