import Vue from 'vue'

import draggable from 'vuedraggable'

// import TextInput from '@/components/report_elements/FormElementTextInput'
// import LongTextInput from '@/components/report_elements/FormElementLongTextInput'
// import NumberInput from '@/components/report_elements/FormElementNumberInput'
// import SelectList from '@/components/report_elements/FormElementSelectList'
// import RadioButton from '@/components/report_elements/FormElementRadioButton'
// import Checkbox from '@/components/report_elements/FormElementCheckbox'
// import CheckboxText from '@/components/report_elements/FormElementCheckboxText'
// import TimePicker from '@/components/report_elements/FormElementTimePicker'
// import DatePicker from '@/components/report_elements/FormElementDatePicker'
// import DatetimePicker from '@/components/report_elements/FormElementDatetimePicker'
// import Rating from '@/components/report_elements/FormElementRating'
// import Button from '@/components/report_elements/FormElementButton'
// import Carousel from '@/components/report_elements/FormElementCarousel'
// import Upload from '@/components/report_elements/FormElementUpload'
// import ElSwitch from '@/components/report_elements/FormElementSwitch'
import TextComponent from '@/components/report_elements/FormElementText'
import TableComponent from '@/components/report_elements/FormElementTable'
import HtmlComponent from '@/components/report_elements/FormElementHtml'
import GraphBar from '@/components/report_elements/FormElementGraphBar'
// import ImageButton from '@/components/report_elements/FormElementImageButton'
// import RadioTextButton from '@/components/report_elements/FormElementRadioTextButton'

import Elements from '@/components/report_elements/Elements'
import Properties from '@/components/report_elements/properties/Properties'

// import vm from '@/main'
// import lodash from 'lodash'
// import { mapState } from 'vuex'
export const ReportBuilder = new Vue({
  components: {
    Elements,
    Properties,
    draggable,
    TextComponent,
    TableComponent,
    HtmlComponent,
    GraphBar
  },
  data() {
    return {
      fields: [
        // {
        //   fieldType: 'TextInput',
        //   name: '',
        //   label: 'Text',
        //   text: 'Text',
        //   group: 'form', //form group
        //   isInline: false,
        //   isRequired: false,
        //   isHelpBlockVisible: false,
        //   isPlaceholderVisible: true,
        //   isUnique: false,
        //   span: 24,
        //   labelCol: 8,
        //   labelWidth: 100,
        //   advancedOptions: true,
        //   showPassword: false,
        //   disabled: false,
        //   clearable: false,
        //   prepend: '',
        //   append: '',
        //   maxlength: 10,
        //   showWordLimit: false
        // },
        // {
        //   fieldType: 'LongTextInput',
        //   name: '',
        //   label: 'Long Text',
        //   text: 'Long Text',
        //   group: 'form',
        //   isRequired: false,
        //   isHelpBlockVisible: false,
        //   isPlaceholderVisible: true,
        //   isUnique: false,
        //   span: 24,
        //   labelWidth: 100
        // },
        // {
        //   fieldType: 'NumberInput',
        //   name: '',
        //   label: 'Number',
        //   text: 'Number',
        //   group: 'form',
        //   isRequired: false,
        //   isHelpBlockVisible: false,
        //   isPlaceholderVisible: false,
        //   isUnique: false,
        //   span: 24,
        //   labelWidth: 100,
        //   advancedOptions: false,
        //   disabled: false,
        //   stepStrictly: false,
        //   step: 1,
        //   hasMinValue: false,
        //   min: 1,
        //   hasMaxValue: false,
        //   max: 10
        // },
        // {
        //   fieldType: 'SelectList',
        //   name: '',
        //   label: 'Select',
        //   text: 'Select',
        //   group: 'form',
        //   isRequired: false,
        //   isHelpBlockVisible: false,
        //   isPlaceholderVisible: false,
        //   isUnique: false,
        //   span: 24,
        //   labelWidth: 100,
        //   isFromUrl: false,
        //   dataUrl: '',
        //   options: [
        //     {
        //       optionLabel: 'Option Label 1',
        //       optionValue: 'Option 1'
        //     },
        //     {
        //       optionLabel: 'Option Label 2',
        //       optionValue: 'Option 2'
        //     }
        //   ],
        //   advancedOptions: false,
        //   labelField: 'label',
        //   valueField: 'value',
        //   disabled: false,
        //   clearable: false,
        //   multiple: false,
        //   filterable: false,
        //   remote: false
        // },
        // {
        //   fieldType: 'RadioButton',
        //   name: '',
        //   label: 'Radio',
        //   text: 'Radio',
        //   group: 'form',
        //   isRequired: false,
        //   isHelpBlockVisible: false,
        //   isPlaceholderVisible: false,
        //   isUnique: false,
        //   span: 24,
        //   labelWidth: 100,
        //   isFromUrl: false,
        //   options: [
        //     {
        //       optionLabel: 'Option Label 1',
        //       optionValue: 'Option 1'
        //     },
        //     {
        //       optionLabel: 'Option Label 2',
        //       optionValue: 'Option 2'
        //     }
        //   ],
        //   advancedOptions: true,
        //   dataUrl: '',
        //   labelField: 'label',
        //   valueField: 'value'
        // },
        // {
        //   fieldType: 'Checkbox',
        //   name: '',
        //   label: 'Checkbox',
        //   text: 'Checkbox',
        //   group: 'form',
        //   isRequired: false,
        //   isHelpBlockVisible: false,
        //   isPlaceholderVisible: false,
        //   isUnique: false,
        //   span: 24,
        //   labelWidth: 100,
        //   isFromUrl: false,
        //   min: 1,
        //   max: 5,
        //   options: [
        //     {
        //       optionLabel: 'Option Label 1',
        //       optionValue: 'Option 1'
        //     },
        //     {
        //       optionLabel: 'Option Label 2',
        //       optionValue: 'Option 2'
        //     }
        //   ],
        //   advancedOptions: true,
        //   dataUrl: '',
        //   labelField: 'label',
        //   valueField: 'value'
        // },
        // {
        //   fieldType: 'Rating',
        //   name: '',
        //   label: 'Rating',
        //   text: 'Rating',
        //   group: 'form',
        //   isRequired: false,
        //   isHelpBlockVisible: false,
        //   isPlaceholderVisible: false,
        //   isUnique: false,
        //   span: 24,
        //   labelWidth: 100,
        //   advancedOptions: true,
        //   rateValue: 0,
        //   showText: true,
        //   disabled: false,
        //   showScore: false,
        //   scoreUnit: 'points',
        //   max: 5,
        //   colors: ['#AAAAAA', '#F7BA2A', '#FF9900'],
        //   texts: ['oops', 'disappointed', 'normal', 'good', 'great']
        // },
        // {
        //   fieldType: 'ElSwitch',
        //   name: '',
        //   label: 'Switch',
        //   text: 'Switch',
        //   group: 'form',
        //   isUnique: false,
        //   span: 24,
        //   labelWidth: 100,
        //   activeText: '',
        //   inActiveText: ''
        // },
        // {
        //   fieldType: 'Button',
        //   name: '',
        //   text: 'Button',
        //   group: 'button',
        //   buttonType: '',
        //   buttonText: 'Submit your form',
        //   isRequired: false,
        //   isHelpBlockVisible: false,
        //   isPlaceholderVisible: false,
        //   isUnique: true,
        //   span: 24,
        //   labelWidth: 100
        // },
        // {
        //   fieldType: 'Carousel',
        //   name: '',
        //   text: 'Carousel',
        //   group: 'static',
        //   isUnique: false,
        //   span: 24,
        //   imageFit: 'contain',
        //   labelWidth: 100,
        //   controlHeight: 150,
        //   itemsNumber: 4,
        //   items: [
        //     {
        //       url: ''
        //     }
        //   ]
        // },
        // {
        //   fieldType: 'Upload',
        //   name: '',
        //   label: 'Upload',
        //   text: 'UploadFiles',
        //   group: 'form',
        //   isUnique: false,
        //   span: 24,
        //   uploadURL: 'https://jsonplaceholder.typicode.com/posts/'
        // },
        // TextComponent
        {
          fieldType: 'TextComponent',
          label: 'Text',
          text: 'Text',
          group: 'form', //form group
          isInline: false,
          isHelpBlockVisible: false,
          isPlaceholderVisible: false,
          isUnique: false,
          span: 24,
          labelCol: 8,
          labelWidth: 100,
          showPassword: false,
          disabled: false,
          clearable: false,
          prepend: '',
          append: '',
          maxlength: 10,
          showWordLimit: false
        },
        // TableComponent
        {
          fieldType: 'TableComponent',
          name: '',
          text: 'Table',
          group: 'form',
          isUnique: false,
          span: 24,
          tableColumns: [
            {
              prop: 'date',
              label: 'Date',
              width: 180
            },
            {
              prop: 'name',
              label: 'Name',
              width: 180
            },
            {
              prop: 'address',
              label: 'Address'
            }
          ],
          tableDatas: [
            {
              id: 1,
              edit: false,
              date: '2016-05-03',
              name: 'Tom',
              address: 'No. 189, Grove St, Los Angeles'
            }
          ]
        },
        // HtmlComponent
        {
          fieldType: 'HtmlComponent',
          name: '',
          text: 'Html',
          group: 'static',
          isUnique: false,
          span: 24,
          htmlContent: '<h3>Hello World</h3>',
          advancedOptions: ''
        },
        // GraphBar
        {
          fieldType: 'GraphBar',
          name: '',
          text: 'Graph Bar',
          group: 'static',
          isUnique: false,
          span: 12,
          // height: 24,
          setting: {
            type: 'bar',
            multiple: false
          },
          dataFieldList: [
            {
              label: 'Field 1',
              name: 'field1'
            },
            {
              label: 'Field 2',
              name: 'field2'
            },
            {
              label: 'Field 3',
              name: 'field3'
            }
          ],
          data: {
            datasets: []
          },
          chartDataSetting: [],
          chartData: [
            {
              label: 'Question 1',
              fielName: '',
              value: 20
            },
            {
              label: 'Question 1',
              fielName: '',
              value: 30
            }
          ]
        }
      ],
      sortElementOptions: {
        group: {
          name: 'reportbuilder',
          pull: false,
          put: true
        },
        sort: true
      },

      dropElementOptions: {
        group: {
          name: 'reportbuilder',
          pull: 'clone',
          put: false
        },
        sort: false,
        filter: '.is-disabled'
      }
    }
  },
  methods: {
    getValue(fieldType, field) {
      if (fieldType == 'Rating') {
        return { fieldType, label: field.label, value: field.rateValue }
      } else {
        // RadioButton, Rating, CheckboxText, LongTextInput
        return { fieldType, label: field.label, value: field.value }
      }
    }
  }
})
