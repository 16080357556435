<template>
  <div class="report-text" :span="24">{{ currentField.label }}</div>
</template>

<script>
export default {
  name: 'TextComponent',
  props: ['currentField']
}
</script>
<style scoped>
.report-text {
  font-size: 18px;
  font-weight: bold;
}
</style>
