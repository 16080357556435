<template>
  <div class="el-tabs__inner">
    <el-row :gutter="10" class="row-bg">
      <draggable
        :list="fields"
        :clone="clone"
        class="dragArea"
        :group="{ name: 'formbuilder', pull: 'clone', put: false }"
        :sort="false"
      >
        <el-col
          :span="12"
          :class="{ 'is-disabled': checkStopDragCondition(field) }"
          v-for="(field, index) in fields"
          :key="index"
        >
          <el-button class="button__sidebar" size="small">
            {{ field.text }}
          </el-button>
        </el-col>
      </draggable>
    </el-row>
  </div>
</template>

<script>
import { ReportBuilder } from '@/components/report_elements/reportbuilder'
import draggable from 'vuedraggable'
import lodash from 'lodash'
import { mapState } from 'vuex'
export default {
  name: 'Elements',
  // store: ['forms'],
  components: {
    draggable
  },
  data() {
    return {
      fields: ReportBuilder.$data.fields,
      dropElementOptions: ReportBuilder.$data.dropElementOptions
    }
  },
  computed: {
    ...mapState(['report'])
  },
  methods: {
    clone(field) {
      return lodash.cloneDeep(field)
    },
    checkStopDragCondition(field) {
      var form = this.report,
        formArray = []

      for (var key in form) {
        formArray.push(form[key]['fieldType'])
      }
      // Check if the fieldname exist in formArray
      // And when the field.isUnique too
      return lodash.includes(formArray, field.name) && field.isUnique
    }
  }
}
</script>

<style lang="scss" scoped>
.button__sidebar {
  width: 100%;
  margin-bottom: 5px;

  .is-disabled & {
    opacity: 0.4;
  }
}

// Display this ghost in <main> only
.sortable__ghost {
  position: relative;
  width: 33.33%;
  border-bottom: 2px solid #3a8ee6;
  margin-top: 2px;
  margin-bottom: 2px;

  [type='button'] {
    display: none;
  }

  &:before {
    content: 'Drag it here';
    background-color: #ecf5ff;
    color: #3a8ee6;
    position: absolute;
    left: 50%;
    font-size: 10px;
    border-radius: 10px;
    line-height: 15px;
    padding: 0 5px;
    top: -6px;
    transform: translateX(-50%);
  }
}
</style>
