<template>
  <div class="list-table">
    <v-container>
      <v-row>
        <v-col cols="12">
          <el-tabs v-model="activeName">
            <el-tab-pane label="ออกแบบรายงาน" name="Design">
              <DesignFormReport />
            </el-tab-pane>
            <!-- <el-tab-pane label="ดูตัวอย่าง" name="Preview"
              ><Preview
            /></el-tab-pane> -->
          </el-tabs>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import DesignFormReport from '@/components/report_elements/Design'
// import Preview from '@/components/form_elements/Preview'

export default {
  name: 'DesignReport',
  data() {
    return {
      activeName: 'Design'
    }
  },
  components: {
    DesignFormReport
    // Preview
  }
}
</script>
